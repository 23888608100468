import { graphql, useStaticQuery } from "gatsby"

import LocaleContext from "../../localeContext"
import React from "react"

function useTranslations() {
  // Grab the locale (passed through context) from the Context Provider
  const { locale } = React.useContext(LocaleContext)
  // Query the JSON files in <rootDir>/i18n/translations
  const { rawData } = useStaticQuery(query)

  // Simplify the response from GraphQL
  const simplified = rawData.edges.map(item => {
    return {
      name: item.node.name,
      translations: item.node.translations,
    }
  })
  // Only return translations for the current locale
  const { translations } = simplified.filter(lang => lang.name === locale)[0]

  return translations
}

export default useTranslations

const query = graphql`
  query useTranslations {
    rawData: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            genel {
              iletisim
              hakkimizda
              referanslarimizdanBazilari
              iletisimeGec
              kutuphaneProgrami
              cekilisSitesi
              ucretsizDene
              detaySayfasi
              hemenDene
              programlar
              yazilimlar
              programMerkezi
              tusunamiKutuphaneTakipProgrami
              servishaneTeknikServisProgrami
              cekilisAdamHizliCekilisSitesi
              akilliTahtaKilit
              tusunamiCekilisProgrami
              indirmeler
              kodvizitHakkinda
              sirketBilgileri
              blog
              hesapBilgileri
              kullanimKosullari
              gizlilikPolitikasi
              mesafeliSatisSozlesmesi
              iadeveTeslimatSartlari
              lisansAl
              lisansSatinAl
              whatsappDestek
              telegramDestek
              dahaFazla
              yasal
              yazilim
              cekilisProgrami
              kodvizitTarafindanHazirlandi
              anasayfa
              indirmeMerkezi
              harita
              bilgiler
              sirket
              telefon
              ePosta
              kullanimKilavuzu
              destek
              mobil
              adres
              konum
              kutuphaneOtomasyonu
              hizliCekilisSitesi
              siteyeGit
              cekilisYapmaYazilimi
              indir
              hemenIndir
              aklliKilit
              cekilisOtomasyonu
              sureyleKullanilabilir
              satinAl
              ozellikler
              sikSorulanSorular
              ekranGoruntuleri
              referanslar
              paketler
              dahaFazlaBilgiMiGerekli
            }
            index {
              seoTitle
              seoDescription
              isinizi
              kolaylastiriyoruz
              ustAciklama
              anindaDestek
              anindaDestekMetin
              uyumluTasarim
              uyumluTasarimMetin
              guvenlik
              guvenlikMetin
              memnuniyet
              memnuniyetMetin
              kutuphaneAltMetin
              cekilisSiteAltMetin
              ucretsizCekilisYapin
              ucretsizCekilisYapinMetin
              kaydedinVePaylasin
              kaydedinVePaylasinMetin
              deneyimleyin
              deneyimleyinMetin
              sizeOzelProjeniziKonusalim
            }
            e404 {
              seoTitle
              seoDescription
              aradiginizSayfayiBulamadik
            }
            blog {
              seoTitle
              seoDescription
              devaminiOku
            }
            hakkimizda {
              seoTitle
              seoDescription
              misyonumuz
              baslikYazi1
              baslikYazi2
              hakkimizdaParagraf1
              hakkimizdaParagraf2
              vergiDairesiveNumarasi
              mersisNumarasi
              ticaretSicilNumarasi
              sermaye
            }
            iletisim {
              seoTitle
              seoDescription
              baslikSatir1
              baslikSatir2
              baslikSatir3
              destekAciklama
            }
            indirme {
              seoTitle
              seoDescription
              kodvizittenIndir
              baslikSatir2
              baslikSatir3
              destekAciklama
              uygundur1
              uygundur2
              sonSurum
              yayinlanmaTarihi
            }
            programlar {
              seoTitle
              seoDescription
              kurumsal
              cekilisSistemleri
              aracGerec
              geriSayimAraclari
              kutuphaneAciklama
              akilliKilitAciklama
              durduruldu
              cekilisSiteAciklama
              cekilisProgramAciklama
              qrKodOlusturucu
              kareKodOlusturucu
              qrKodAciklama
              kimlikDogrulayici
              tcKimlikNoDogrulayici
              kimlikDogrulayiciAciklama
              ygsGeriSayim
              sinavGeriSayimAraci
              ygsGeriSayimAciklama
              okulSayar
              okulGeriSayimAraci
              okulSayarAciklama
            }
            magaza {
              seoTitle
              seoDescription
              kutuphaneProgramiPaketleri
              temel
              yil
              Gun14BoyuncaUcretsiz
              BarkodOkumaVeYazdirma
              VeriAktarmaSihirbazi
              DetayliRaporlama
              CokluKullaniciIleKullanim
              SunucuPaylasimi
              UcretsizBarkodOkuyucuCihaz
              KurumunuzunAdiLogosuVeIkonu
              KitapListesiPaylasimEkrani
              SanalDestekTelefon
              sikSorulanSorular
              sinirsiz
              soru1
              cevap1
              soru2
              cevap2
              soru3
              cevap3
              soru4
              cevap4
              soru5
              cevap5
              soru6
              cevap6
              soru7
              cevap7
              soru8
              cevap8
            }
            cekilis {
              hizliSeoTitle
              hizliSeoDesc
              hizliCekilisYap
              katilimciListesiPlaceHolder
              kazanacakKisiSayisi
              yedekKazananlarOlsun
              cekilisiBaslat
              cekilisiSonlandir
              cekilisYapiliyor
              kazanan
              nasilYapilirHemenIzle
              cekilisSonucunuKaydetvePaylas
              kazananlar
              yedekKazananlar
              cekilisSonucunuKaydet
              cekilisiYapan
              zorunluDegil
              aciklama
              aciklamaPlaceholder
              cekilisDeneyiminizNasildi
              robotDegilsinizdirAmaEminOlalim
              cekilisiKaydet
              iptal
              afilliSeoTitle
              afilliSeoDesc
              afilliCekilisYap
              isimleriListeyeYazipCekilisiBaslatin
              carkSeoTitle
              carkSeoDesc
              carkCekilisYap
              rastgeleSayiSeoTitle
              rastgeleSayiSeoDesc
              rastgeleSayiUret
              sayiAraligi
              minimum
              maksimum
              uret
              zarSeoTitle
              zarSeoDesc
              zarAt
              atilacakZarSayisi
              toplam
              yaziTuraSeoTitle
              yaziTuraSeoDesc
              yaziTuraAt
              paraAt
              atilacakParaSayisi
              instagramSeoTitle
              instagramSeoDesc
              instagramCekilisAraci
              tikTokSeoTitle
              tikTokSeoDesc
              tikTokCekilisAraci
              uyari
              uyariParagraf1
              uyariParagraf2
              uyariParagraf3
              tikTokUyariParagraf3
              instagramPlaceholder
              yorumdaSuKelimelerinTamamiOlmali
              yorumdaSuKelimelerPlaceholder
              tekrarEtmeLimiti
              enAzMentionAd
              enAzHashtagAd
              yorumlariAyristirveKullaniciAdlariniGetir
              nasilYapilir
              nasilYapilirAciklama
              adim1
              adim2
              adim3
              adim4
              adim5
              adim6
              tikTokAdim1
              tikTokAdim2
              tikTokAdim3
              tikTokAdim4
              tikTokAdim5
              tikTokAdim6
              kullaniciIsimleriBuradaGorunecek
              kaynakKoduHatali
              buKadarFazlaKatilimciKayitEdemiyoruz
              lutfenGuvenlikTamamlayin
              cekilisSonucunuPaylas
              cekilisSonucuBelliOldu
              cekilisSonucuBelliOlduMail
              cekilisSonucHashtag
              adresindenBakabilirsin
              cekilisSonucMailBody
              hizliCekilis
              afilliCekilis
              cark
              rastgeleSayi
              zar
              yaziTura
              instagramAraci
              tikTokAraci
            }
            kutuphane {
              seoTitle
              seoDesc
              baslik
              altBaslik
              altBaslikAlti
              kullaniciMesaji
              kullaniciAdi
              indirButonuYazi
              butonAltiYazi
              referansUstuYazi
              footerSeoMetin
              bolumler {
                baslik
                aciklama
                maddeler
              }
            }
            kutuphaneOzellikler {
              seoTitle
              seoDescription
              barkodSistemi
              hazirKitaplar
              raporSistemi
              veriAktarmaSihirbazi
              cokluKullaniciSistemi
              barkodOkumaYazdirma
              yedeklemeVeGeriYukleme
              kasaSistemi
              okuyucuEkleme
              sinifYukseltDusur
              hasarliKayip
              anindaOkuyucuKaydi
              barkodSistemiDetay
              hazirKitaplarDetay
              raporSistemiDetay
              veriAktarmaSihirbaziDetay
              cokluKullaniciSistemiDetay
              barkodOkumaYazdirmaDetay
              yedeklemeVeGeriYuklemeDetay
              kasaSistemiDetay
              okuyucuEklemeDetay
              sinifYukseltDusurDetay
              hasarliKayipDetay
              anindaOkuyucuKaydiDetay
            }
            kutuphaneReferanslar {
              seoTitle
              seoDescription
              referansDetay
              kurum
              ilIlce
            }
            cekilisSite {
              seoTitle
              seoDescription
              baslik
              altBaslik
              altBaslikAlti
              kullaniciMesaji
              kullaniciAdi
              indirButonuYazi
              butonAltiYazi
              bolumler {
                baslik
                aciklama
                maddeler
              }
            }
            cekilisSiteOzellikler {
              seoTitle
              seoDescription
              paragraf1
              paragraf2
              paragraf3
              paragraf4
            }
            cekilisProgram {
              seoTitle
              seoDescription
              baslik
              altBaslik
              altBaslikAlti
              kullaniciMesaji
              kullaniciAdi
              indirButonuYazi
              butonAltiYazi
              bolumler {
                baslik
                aciklama
              }
            }
            cekilisProgramOzellikler {
              seoTitle
              seoDescription
              paragraf1
              paragraf2
            }
            akilliKilit {
              seoTitle
              seoDescription
              baslik
              altBaslik
              altBaslikAlti
              kullaniciMesaji
              kullaniciAdi
              indirButonuYazi
              butonAltiYazi
              seoFooterParagraf1
              seoFooterParagraf2
              seoFooterParagraf3
              bolumler {
                baslik
                aciklama
                maddeler
              }
            }
            akilliKilitOzellikler {
              seoTitle
              seoDescription
              paragraf1
              paragraf2
              paragraf3
            }
            servishane {
              seoTitle
              seoDescription
              baslik
              altBaslik
              altBaslikAlti
              kullaniciMesaji
              kullaniciAdi
              indirButonuYazi
              butonAltiYazi
              referansUstuYazi
              bolumler {
                baslik
                aciklama
                maddeler
              }
            }
            servishaneMagaza {
              seoTitle
              seoDescription
              servishanePaketleri
              ucretsizUyelik
              standartUyelik
              vipUyelik
              sizeOzelProgram
              ucretsiz
              kaynakKodTeklifIsteyiniz
              moduller
              ozelModuller
            }
            servishaneSSS {
              seoTitle
              seoDescription
              sss {
                soru
                cevap
              }
            }
          }
        }
      }
    }
  }
`
